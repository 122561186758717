<template>
  <descriptive-content
    :category="category"
    :layout="layout"
    :is-digital-signage="isDigitalSignage"
    :breadcrumb="breadcrumb">
    <div
      id="player"
      class="video">
    </div>
  </descriptive-content>
</template>

<script>
  import OvenPlayer from 'ovenplayer'

  export default {
    props: [
      'layout',
      'category',
      'isDigitalSignage',
    ],
    computed: {
      createSources() {
        let streamType = this.$helper.findAttributeValue(this.category, 'CMS-StreamType')
        switch (`${streamType}`.toLowerCase()) {
          case 'webrtc':
            return [{
              label: 'WebRTC',
              type: 'webrtc',
              file: this.category.streamurlWs
            }]
          case 'hls':
          default:
            return [
              { label: 'HLS', type: 'hls', file: this.category.streamurl },
              { label: 'HLS 360', type: 'hls', file: this.category.streamurl360 },
              { label: 'HLS 480', type: 'hls', file: this.category.streamurl480 },
              { label: 'HLS 720', type: 'hls', file: this.category.streamurl720 },
            ]
        }
      },
      getImage() {
        const media = this.category?.media?.links?.find(l => l.rel === 'preview')
        if (media) {
          return media.href
        }
        return ''
      }
    },
    methods: {
      loadPlayer() {
        if (this.category) {
          if (this.player) {
            this.player.remove();
            this.player = null;
          }
          this.player = OvenPlayer.create('player', {
            sources: this.createSources,
            image: this.getImage,
            autoStart: true,
            mute: false,
            controls: false,
            showBigPlayButton: false,
            autoFallback: false,
          });
          this.player.on('ready', () => {
            this.ready()
          })
          this.player.on('stateChanged', () => {
            this.stateChanged()
          })
          this.player.once('error', () => {
            this.onceError()
          })
        }
      },
      onceError() {
          if (this.$data.reloadTimer) {
            clearInterval(this.$data.reloadTimer);
            this.$data.reloadTimer = null;
          }
          this.$data.reloadTimer = setTimeout(this.loadPlayer, 4000);
      },
      ready() {
        this.player.getConfig().systemText.api.error[501].message = '';
        this.player.getConfig().systemText.api.error[302].message = '';
        this.player.getConfig().systemText.api.error[303].message = '';
        this.player.getConfig().systemText.api.error[308].message = '';
      },
      stateChanged() {
        if (this.player.getState() === "playing" || this.player.getState() === "paused"){
          this.player.showControls(true);
        }
      }
    },
    data() {
      return {
        player: null,
        reloadTimer: null,
      }
    },
    mounted() {
      this.loadPlayer()
    },
    beforeDestroy () {
      if (this.player) {
        this.player.remove()
      }
    },
    inject: [
      '$helper'
    ]
  }
</script>

<style lang="scss" scoped>
  .video {
    @apply
      w-full
      h-full
      max-w-full
      max-h-full;
  }
</style>
<style>
.op-message-container {
  display: none !important;
}
</style>